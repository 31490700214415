/*=======================
   Helper classes
========================= */
@import "../base/variables";

// Margin Top
@for $i from 1 through 20 {
  .mt-#{$i * 5} {
    margin-top: $i * 5px;
  }
}

// Margin Top Responsive
@for $i from 1 through 20 {
  .mt-sm-#{$i * 5} {
    @media #{$sm-device} {
      margin-top: $i * 5px;
    }
  }
}
@for $i from 1 through 20 {
  .mt-md-#{$i * 5} {
    @media #{$md-device} {
      margin-top: $i * 5px;
    }
  }
}

// Margin Top Minus
@for $i from 1 through 20 {
  .mtn-#{$i * 5} {
    margin-top: $i * -5px;
  }
}

// Margin Top Minus Responsive
@for $i from 1 through 20 {
  .mtn-sm-#{$i * 5} {
    @media #{$sm-device} {
      margin-top: $i * -5px;
    }
  }
}
@for $i from 1 through 20 {
  .mtn-md-#{$i * 5} {
    @media #{$md-device} {
      margin-top: $i * -5px;
    }
  }
}

// Margin Bottom
@for $i from 1 through 20 {
  .mb-#{$i * 5} {
    margin-bottom: $i * 5px;
  }
}

// Margin Bottom Responsive
@for $i from 1 through 20 {
  .mb-sm-#{$i * 5} {
    @media #{$sm-device} {
      margin-bottom: $i * 5px;
    }
  }
}
@for $i from 1 through 20 {
  .mb-md-#{$i * 5} {
    @media #{$md-device} {
      margin-bottom: $i * 5px;
    }
  }
}