/*=====================
06. Form Style CSS
====================== */

.form-input-item{
  margin-top: 20px;
  input{
    background-color: $offWhite;
    border: none;
    display: block;
    font-size: 16px;
    padding: 15px 20px;
    width: 100%;
  }

  .btn-submit{
    background-color: $brandColor;
    border: 2px solid $brandColor;
    color: $white;
    display: block;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 20px;
    width: 100%;

    &:hover{
      color: $brandColor;
      background-color: transparent;
    }
  }

  &--outline{
    input{
      background-color: transparent;
      border: 2px solid $brandColor;
    }

    .btn-submit{
      background-color: transparent;
    }
  }
}