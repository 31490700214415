/*===========================
24. Skill Bar Style CSS
============================*/
.single-skill-bar {
  &:not(:first-child){
    margin-top: 30px;
  }

  .skills-info {
    margin-bottom: 20px;

    .skill-title, .skill-percent {
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
      margin: 0;
    }

    .skill-percent {
      font-weight: 600;
    }
  }

  .progress {
    background-color: #eeeeee;
    border-radius: 0;
    height: 10px;

    .progress-bar {
      background-color: $brandColor;
      transition-delay: 0.4s;
      transition-duration: 0.6s;
      width: 0;
    }
  }
}